import { gql } from "graphql-request";
import type { NextPage } from "next";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import type { FC, FormEventHandler } from "react";
import { useState } from "react";

import Button from "components/Button";
import CloseButton from "components/CloseButton";
import Container from "components/Container";
import Heading from "components/Heading";
import IconButton from "components/IconButton";
import Logo from "components/Logo";
import type { NavItems } from "components/navigation/Nav";
import Nav, { navItemsSubquery } from "components/navigation/Nav";
import OpenGraph, { openGraphSubquery } from "components/OpenGraph";
import DiscordIcon from "components/svgs/DiscordIcon";
import TwitterIcon from "components/svgs/TwitterIcon";
import { useAuthRedirect } from "hooks/auth";
import useLocalStorage from "hooks/local-storage";
import { cmsClient } from "lib/cms-client";
import { isFlagOn } from "lib/feature-flags";

export const splashBackgroundSubquery = `
  splashPage(where: { id: "ckzyhf1k82w450c69k6ff17vc" }) {
    backgroundImage { url }
  }
`;

interface SplashBackgroundProps {
  imageUrl?: string;
  children: any;
}

const SplashBackground: FC<SplashBackgroundProps> = ({
  imageUrl,
  children,
}) => {
  useAuthRedirect();

  return (
    <div
      className="
        bg-cover bg-center bg-slate-900
        absolute inset-0
      "
      style={{ backgroundImage: `url('${imageUrl}')` }}
    >
      <Container
        className="
          w-full h-full min-h-0
          flex flex-col justify-center items-center
          overflow-y-auto
        "
      >
        {children}
      </Container>
    </div>
  );
};

interface SplashProps {
  openGraphItem?: any;
  backgroundImage?: { url: string };
  ctaText: string;
  quizCtaText?: string;
  modalHeading: string;
  twitterButtonText: string;
  twitterButtonUrl: string;
  discordButtonText: string;
  discordButtonUrl: string;
  emailFieldLabelText: string;
  emailFieldPlaceholderText: string;
  emailSubmitButtonText: string;
  signUpThankYouHeading: string;
  signUpThankYouCopy: string;
  navItems: NavItems;
  domos?: [
    {
      name: string;
      openGraphImage: { url: string };
    }
  ];
  quizActive?: boolean;
  notification?: { id: string; details: { html: string } };
}

const SplashJoinFlow: FC<Omit<SplashProps, "navItems">> = ({
  ctaText,
  quizCtaText,
  quizActive,
  modalHeading,
  twitterButtonText,
  twitterButtonUrl,
  discordButtonText,
  discordButtonUrl,
  emailFieldLabelText,
  emailFieldPlaceholderText,
  emailSubmitButtonText,
  signUpThankYouHeading,
  signUpThankYouCopy,
}) => {
  let [joinActive, setJoinActive] = useState(false);
  let [emailSubmitted, setEmailSubmitted] = useState(false);

  let onEmailSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    let response = await fetch("/api/news-signup", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: event.currentTarget.email.value }),
    });

    if (response.ok) {
      setEmailSubmitted(true);
    }
  };

  const CTAButton = () => (
    <>
      {process.env.NEXT_PUBLIC_MINTING_ALLOWED ||
      process.env.NEXT_PUBLIC_PREVIEW ||
      process.env.NODE_ENV !== "production" ? (
        <Button
          href="/connect"
          text="Connect"
          className="min-w-[255px] mt-10"
        />
      ) : (
        <Button
          onClick={() => setJoinActive(true)}
          text={ctaText}
          className="min-w-[255px] mt-10"
        />
      )}
    </>
  );

  return (
    <>
      <CTAButton />
      {quizActive && (
        <Button
          href="/quiz"
          text={quizCtaText || ""}
          style="border-white"
          className="text-white min-w-[255px] mt-5"
        />
      )}

      {joinActive && (
        <article
          className="
            uppercut-fade
            backdrop-blur-md
            bg-dark/90 fixed inset-0 z-20
            min-h-0
            flex flex-col md:flex-row justify-center items-center
            text-white text-center
          "
        >
          <CloseButton
            onClick={() => setJoinActive(false)}
            className="absolute top-8 sm:top-10 right-4 sm:right-10 active:opacity-50"
          />

          <div className="w-full max-w-sm px-4 py-20 grow overflow-y-auto">
            <Heading text={modalHeading} className="mb-10" />

            <ul className="flex flex-col gap-4">
              {twitterButtonUrl && twitterButtonText && (
                <li>
                  <IconButton
                    icon="twitter"
                    target="_blank"
                    href={twitterButtonUrl}
                    text={twitterButtonText}
                  />
                </li>
              )}
              {discordButtonUrl && discordButtonText && (
                <li>
                  <IconButton
                    icon="discord"
                    target="_blank"
                    href={discordButtonUrl}
                    text={discordButtonText}
                  />
                </li>
              )}
            </ul>

            <div className="mt-12 pt-12 border-t border-white">
              {!emailSubmitted ? (
                <form className="flex flex-col gap-4" onSubmit={onEmailSubmit}>
                  <label
                    htmlFor="email"
                    className="block text-2xl font-heading font-medium uppercase tracking-wider mb-3"
                  >
                    {emailFieldLabelText}
                  </label>

                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder={emailFieldPlaceholderText}
                    className="text-xl text-black text-center p-5"
                    required
                  />

                  <Button onClick={() => null} text={emailSubmitButtonText} />
                </form>
              ) : (
                <>
                  <Heading text={signUpThankYouHeading} />
                  <p className="mt-5">{signUpThankYouCopy}</p>
                </>
              )}
            </div>
          </div>
        </article>
      )}
    </>
  );
};

const Splash: NextPage<SplashProps> = ({
  openGraphItem,
  backgroundImage,
  ctaText,
  quizCtaText,
  modalHeading,
  twitterButtonText,
  twitterButtonUrl,
  discordButtonText,
  discordButtonUrl,
  emailFieldLabelText,
  emailFieldPlaceholderText,
  emailSubmitButtonText,
  signUpThankYouHeading,
  signUpThankYouCopy,
  navItems,
  domos,
  quizActive,
  notification,
}) => {
  const session = useSession();
  const [notificationsSeen, setNotificationsSeen] = useLocalStorage<string[]>(
    "IS_ACTIVE_HOME_BANNER_CLOSED",
    []
  );

  // Dynamically set the open graph image based on passed `domos` query
  // parameter.
  const { query } = useRouter();
  if (openGraphItem && query.domos && domos) {
    let ogDomo = domos.find((domo) => domo.name === query.domos);
    if (ogDomo?.openGraphImage) {
      openGraphItem.image = ogDomo?.openGraphImage;
    }
  }

  return (
    <>
      {openGraphItem && <OpenGraph item={openGraphItem} />}
      {process.env.NEXT_PUBLIC_SHOW_NOTIFICATION_BANNER &&
        notification &&
        (!notificationsSeen ||
          !notificationsSeen.includes(notification.id)) && (
          <div className="relative px-10 py-2 animate-emerge origin-top z-20 text-center text-shadow bg-gold Uppercut__NotificationBanner">
            <div
              className="w-4/5 mx-auto"
              dangerouslySetInnerHTML={{ __html: notification.details.html }}
            />
            <CloseButton
              color="#232323"
              onClick={() =>
                setNotificationsSeen((prev: string[]) => [
                  ...prev,
                  notification.id,
                ])
              }
              className="absolute right-3 active:opacity-50 scale-60 top-1"
            />
          </div>
        )}
      <Nav session={session.data} items={navItems} transparent={true} />
      <SplashBackground imageUrl={backgroundImage?.url}>
        <div className="mt-auto mx-5">
          <Logo
            textColor="transparent"
            backgroundColor="#f5efe7"
            width={725}
            className="mt-52 drop-shadow-[0_4_70px_rgba(0,0,0,0.6)]"
          />
        </div>
        <SplashJoinFlow
          ctaText={ctaText}
          quizCtaText={quizCtaText}
          quizActive={quizActive}
          modalHeading={modalHeading}
          twitterButtonText={twitterButtonText}
          twitterButtonUrl={twitterButtonUrl}
          discordButtonText={discordButtonText}
          discordButtonUrl={discordButtonUrl}
          emailFieldLabelText={emailFieldLabelText}
          emailFieldPlaceholderText={emailFieldPlaceholderText}
          emailSubmitButtonText={emailSubmitButtonText}
          signUpThankYouHeading={signUpThankYouHeading}
          signUpThankYouCopy={signUpThankYouCopy}
        />

        <div
          className="
            w-full mt-auto pt-10 pb-10
            flex gap-5 justify-center md:justify-end items-center
          "
        >
          <a
            href={twitterButtonUrl}
            target="_blank"
            rel="noreferrer"
            className="active:opacity-50"
          >
            <TwitterIcon width={30} height={25} color="white" />
          </a>

          <a
            href={discordButtonUrl}
            target="_blank"
            rel="noreferrer"
            className="active:opacity-50"
          >
            <DiscordIcon width={45} height={45} color="white" />
          </a>
        </div>
      </SplashBackground>
    </>
  );
};

export async function getStaticProps() {
  let query = gql`
    query {
      ${navItemsSubquery}

      splashPage(where: { id: "ckzyhf1k82w450c69k6ff17vc" }) {
        ${openGraphSubquery}
        backgroundImage { url }
        ctaText
        quizCtaText
        modalHeading
        twitterButtonText
        twitterButtonUrl
        discordButtonText
        discordButtonUrl
        emailFieldLabelText
        emailFieldPlaceholderText
        emailSubmitButtonText
        signUpThankYouHeading
        signUpThankYouCopy
      }

      notifications(orderBy: createdAt_DESC, first: 1) {
        id
        details {
          html
        }
      }

      domos {
        name
        openGraphImage { url }
      }

      featureFlag(where: { name: "quiz" }) {
        activeOnProduction
        activeOnPreview
      }
    }
  `;
  let data = await cmsClient.request(query);
  let { activeOnProduction, activeOnPreview } = data.featureFlag;
  let quizActive = isFlagOn(activeOnPreview, activeOnProduction);

  return {
    props: {
      ...data.splashPage,
      navItems: data.navItems,
      domos: data.domos,
      quizActive,
      notification: (data?.notifications && data?.notifications[0]) || null,
    },
  };
}

export default Splash;
