import cx from "classnames";
import Link from "next/link";
import type { FC } from "react";

interface ButtonProps {
  href?: string;
  target?: string;
  onClick?: (event: any) => any;
  disabled?: boolean;
  download?: boolean;
  text: string;
  style?: "transparent" | "border" | "border-white" | "transparent-white";
  className?: string;
}

const Button: FC<ButtonProps> = ({
  href,
  target,
  onClick,
  disabled = false,
  download = false,
  text,
  style,
  className = "",
}) => {
  const classes = cx(
    "uppercut-clip block relative px-12 py-5 text-xl text-center tracking-wider font-heading font-bold uppercase disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer",
    {
      [`
      before:transition-all before:origin-left
      before:absolute before:inset-0 before:bg-blue before:scale-x-0
      hover:before:scale-x-100 focus:before:scale-x-100
      bg-red text-gold
    `]: !style,
    },
    { "text-black hover:text-blue focus:text-blue": style === "transparent" },
    {
      "text-white hover:text-gold focus:text-gold":
        style === "transparent-white",
    },
    {
      "uppercut-clip-border transition-all text-black hover:text-gold focus:text-gold":
        style === "border",
    },
    {
      "uppercut-clip-border transition-all text-white hover:text-gold focus:text-gold":
        style === "border-white",
    },
    className
  );

  if (onClick) {
    return (
      <button
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        className={classes}
      >
        <span className="relative z-10 cursor-pointer">{text}</span>
      </button>
    );
  } else if (href && href.includes("//")) {
    return (
      <a href={href} target={target} className={classes} download={download}>
        <span className="relative z-10 cursor-pointer">{text}</span>
      </a>
    );
  } else if (href) {
    return (
      <Link href={href}>
        <a target={target} className={classes} download={download}>
          <span className="relative z-10 cursor-pointer">{text}</span>
        </a>
      </Link>
    );
  }

  return null;
};

export default Button;
