import type { FC } from "react";

import TwitterIcon from "components/svgs/TwitterIcon";
import DiscordIcon from "components/svgs/DiscordIcon";
import MetamaskIcon from "components/svgs/MetamaskIcon";
import CoinbaseWalletIcon from "components/svgs/CoinbaseWalletIcon";
import WalletConnectIcon from "components/svgs/WalletConnectIcon";
import FormaticIcon from "components/svgs/FormaticIcon";

interface IconWrapperProps {
  icon:
    | "twitter"
    | "discord"
    | "metamask"
    | "coinbase"
    | "walletconnect"
    | "formatic";
}

const IconWrapper: FC<IconWrapperProps> = ({ icon }) => {
  const iconPicker = {
    twitter: <TwitterIcon width={24} height={20} color="white" />,
    discord: <DiscordIcon width={35} height={35} color="white" />,
    metamask: <MetamaskIcon width={35} height={35} color="white" />,
    coinbase: <CoinbaseWalletIcon width={35} height={35} color="white" />,
    walletconnect: <WalletConnectIcon width={35} height={35} color="white" />,
    formatic: <FormaticIcon width={40} height={40} color="white" />,
  };
  return (
    <span className="w-20 flex justify-center items-center border-l-2">
      {iconPicker[icon]}
    </span>
  );
};

interface IconButtonProps {
  onClick?: (event: any) => any;
  href?: string;
  text: string;
  target?: string;
  icon:
    | "twitter"
    | "discord"
    | "metamask"
    | "coinbase"
    | "walletconnect"
    | "formatic";
  className?: string;
}

const IconButton: FC<IconButtonProps> = ({
  onClick,
  href = "",
  text,
  target,
  icon,
  className = "",
}) => {
  let parentClasses = `
    uppercut-clip-lg uppercut-clip-border-lg
    relative block
    text-white tracking-wider
    ${className}
  `;
  let wrapperClasses = `
    before:transition-all before:origin-left
    before:absolute before:inset-0 before:bg-white/20 before:scale-x-0
    hover:before:scale-x-100
    w-full block flex
  `;
  let textClasses =
    "grow py-8 text-xl md:text-2xl font-heading font-bold uppercase";

  return (
    <>
      {href.length > 0 ? (
        <a href={href} target={target} className={parentClasses}>
          <span className={wrapperClasses}>
            <span className={textClasses}>{text}</span>
            <IconWrapper icon={icon} />
          </span>
        </a>
      ) : (
        <button onClick={onClick} className={parentClasses}>
          <span className={wrapperClasses}>
            <span className={textClasses}>{text}</span>
            <IconWrapper icon={icon} />
          </span>
        </button>
      )}
    </>
  );
};

export default IconButton;
