import { allowlist } from "lib/chain";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useUnauthedRedirect(ignoreRedirect?: boolean) {
  const session = useSession();
  const router = useRouter();
  const [useGuard, setUseGuard] = useState<boolean>();
  const waitingForGuardCalc = useGuard === undefined;
  const params = useMemo(() => new URLSearchParams(), []);

  params.set("alert", "You must be logged in to view this page.");
  params.set("returnTo", router.asPath);

  useEffect(() => {
    if (session?.status !== "loading") {
      setUseGuard(session.status === "unauthenticated" && !ignoreRedirect);
    }
  }, [session, setUseGuard, ignoreRedirect]);

  useEffect(() => {
    useGuard && router.push(`/connect?${params.toString()}`);
  }, [params, router, useGuard]);

  return waitingForGuardCalc || useGuard;
}

export const useAuthRedirect = (): void => {
  const session = useSession();
  const router = useRouter();
  const [useGuard, setUseGuard] = useState<boolean>();

  useEffect(() => {
    if (session?.status !== "loading") {
      setUseGuard(session.status === "authenticated");
    }
  }, [session, setUseGuard]);

  useEffect(() => {
    useGuard && router.push(`/portal`);
  }, [router, useGuard]);
};

export function useAllowlistRedirect() {
  const session = useSession();
  const router = useRouter();
  const [contractCalled, setContractCalled] = useState(false);
  const [userEligible, setUserEligible] = useState(false);
  const checkAllowlist = useCallback(async () => {
    if (session.status === "authenticated") {
      setUserEligible(
        (await allowlist((session.data?.walletAddress as string) || "")) === 1
      );
      setContractCalled(true);
    }
  }, [session]);

  useEffect(() => {
    checkAllowlist();
  }, [checkAllowlist]);

  const useGuard = contractCalled && !userEligible;
  const params = useMemo(() => new URLSearchParams(), []);
  params.set(
    "alert",
    "You are not eligible for the allowlist mint. Please connect a different wallet."
  );

  useEffect(() => {
    useGuard && router.push(`/connect?${params.toString()}`);
  }, [useGuard, params, router]);

  return useGuard;
}

export function useNoActiveHeroRedirect() {
  const session = useSession();
  const router = useRouter();
  const [useGuard, setUseGuard] = useState<boolean>();
  const waitingForGuardCalc = useGuard === undefined;
  const params = useMemo(() => new URLSearchParams(), []);

  params.set(
    "alert",
    "You don't have an active hero set. Acquire a hero here."
  );

  useEffect(() => {
    if (session.status !== "loading") {
      setUseGuard(session.data?.activeHero === null);
    }
  }, [session, setUseGuard]);

  useEffect(() => {
    if (useGuard) {
      params.set(
        "alert",
        "No Uppercut Training Club NFTs found associated with your wallet."
      );
    }
  }, [useGuard, params, router]);

  return waitingForGuardCalc || useGuard;
}

export function useAlert() {
  const session = useSession();
  const router = useRouter();
  const [alertText, setAlertText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [returnTo, setReturnTo] = useState("/");

  useEffect(() => {
    if (router.query.alert) {
      setAlertText(router.query.alert.toString());
    }

    if (router.query.success) {
      setSuccessText(router.query.success.toString());
    }

    if (router.query.returnTo) {
      setReturnTo(router.query.returnTo.toString());
    }

    if (session.status === "authenticated" && returnTo !== "/") {
      router.push(returnTo);
    }
  }, [returnTo, router, session]);

  return { alertText, setAlertText, successText, setSuccessText };
}
