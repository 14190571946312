import type { FC } from "react";

interface FormaticIconProps {
  width: number;
  height: number;
  color: string;
}

const FormaticIcon: FC<FormaticIconProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.235 19H34.49H26.745H19V26.745V34.49V34.9866V42.1899V42.235V49.9348H26.745V42.235V42.1899V34.9866V34.49V26.745H34.49H42.235H49.98V19H42.235Z"
      fill={color}
    />
    <path
      d="M34.5375 42.1899H42.2374V49.9349H42.5083C44.4897 49.9337 46.3897 49.1457 47.7904 47.7441C49.1911 46.3426 49.9779 44.4422 49.9779 42.4607V34.49H34.5375V42.1899Z"
      fill={color}
    />
  </svg>
);

export default FormaticIcon;
