import type { FC } from "react";

interface CoinbaseWalletIconProps {
  width: number;
  height: number;
  color: string;
}

const CoinbaseWalletIcon: FC<CoinbaseWalletIconProps> = ({
  width,
  height,
  color,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 21.0318C17.4359 21.0318 21.0318 17.4359 21.0318 13C21.0318 8.56413 17.4359 4.96815 13 4.96815C8.56413 4.96815 4.96815 8.56413 4.96815 13C4.96815 17.4359 8.56413 21.0318 13 21.0318ZM13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
      fill={color}
    />
    <rect
      x="10.7642"
      y="10.7643"
      width="4.47134"
      height="4.47134"
      fill={color}
    />
  </svg>
);

export default CoinbaseWalletIcon;
